<template>
  <UiPopup
    v-model="modelValue"
    title="Save contact edits?"
    description="You are up to close tab without saving new contact info. Do you want to save it?"
    primary-button-text="Save"
    secondary-button-text="Close without savings"
    size="extraSmall"
    :loading="loading"
    @update:model-value="modelValue = false"
    @confirm="save"
    @cancel="emits('cancel'), (modelValue = false)"
  ></UiPopup>
</template>

<script setup lang="ts">
import type { Deal } from '~/types'

const emits = defineEmits(['input', 'cancel'])

type Props = {
  deal: Deal
}
const props = defineProps<Props>()

const modelValue = defineModel<Boolean>({ required: true })

const loading = ref(false)

const save = async () => {
  loading.value = true

  await useUpdateDeal({
    ...props.deal,
    agent_id: props.deal.agent?.id || undefined,
    manager_id: props.deal.manager?.id || undefined,
    lead_id: props.deal.lead?.id,
    source_id: props.deal.source?.id,
    type_id: props.deal.type?.id,
    project_id: props.deal.project?.id,
    developer_id: props.deal.developer?.id,
    stage_id: props.deal.stage?.id,
    status_id: props.deal.status?.id,
    contacts: props.deal.contacts.map((c) => ({
      ...c,
      country_id: c.country_id || c.country?.id,
      type_id: c.type_id || c.type?.id,
    })),
    partner: props.deal.partner || {},
  })
  emits('input')
  modelValue.value = false
  loading.value = false
}
</script>

<style scoped></style>
